 .modalBucket {
     display: none;
     position: fixed;
     z-index: 999999;
     left: 0;
     top: 0;
     overflow: auto;
     background-color: rgb(0, 0, 0);
     background-color: rgba(0, 0, 0, 0.4);
     .modal-content-bucket {
         position: relative;
         background-color: #fefefe;
         margin: 0;
         padding: 0;
         border: 1px solid #888;
         top: 20px;
         left: 20px;
         box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
         -webkit-animation-name: animatetop;
         -webkit-animation-duration: 0.4s;
         animation-name: animatetop;
         animation-duration: 0.4s;
         overflow: auto;
         -webkit-overflow-scrolling: touch;
     }
     .close-bucket {
         position: absolute;
         top: 10px;
         right: 17px;
         padding: 5px;
         cursor: pointer;
         font-size: 1em;
         line-height: 0.9em;
         display: inline-block;
         background: #f4f4f4;
         color: white;
         border-radius: 50%;
         width: 21px;
         height: 21px;
         text-align: center;
         font-weight: 100;
         z-index: 9;
         font-family: arial;
         box-sizing: border-box;
         &:hover {
             background: #9B9B9B;
             color: white;
         }
     }
     iframe {
         border: 0px;
         position: absolute;
     }
 }
 
 @-webkit-keyframes animatetop {
     from {
         top: -300px;
         opacity: 0
     }
     to {
         top: 20px;
         opacity: 1
     }
 }
 
 @keyframes animatetop {
     from {
         top: -300px;
         opacity: 0
     }
     to {
         top: 20px;
         opacity: 1
     }
 }
 
 .modalBucket .modalBucket .close-bucket:hover {}
 
 .btn-outline-bucket {
     font-size: 12px;
     min-height: 30px;
     line-height: 1.5em;
     box-shadow: none;
     background-color: transparent;
     border: 1px solid #0FBFD7;
     border-radius: 2px;
     padding: 0 16.5px;
     color: #0FBFD7;
     -webkit-transition: all 0.3s;
     transition: all 0.3s;
     font-weight: 400;
     padding: 7px 16px;
 }
 
 .btn-outline-bucket:hover {
     background-color: #0FBFD7;
     color: #fff;
     cursor: pointer;
 }
 
 .open-modal-bucket {
     overflow: hidden !important;
 }
